import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { Glyphicon, Label, FormGroup, Checkbox, ControlLabel, Radio } from 'react-bootstrap';
import { compose, memoizeWith, identity } from 'ramda';

import tehApi from 'api/teh';
import supervisorApi from 'api/supervisors';

import ApplConstants from 'constants/Appl';
import OrderConstants from 'constants/Orders';

import Loader from 'components/loader/Loader';
import Table from 'components/table/Table';
import Paginator from 'components/paginator/Paginator';
import Filter from 'components/filter/Filter';
import SupervisorPanel from 'components/supervisorPanel/SupervisorsPanel';
import { withInformer } from 'components/informer/Informer';
import withUser from 'components/hocs/withUser';
import History from 'pages/history/History';
import { getIntervalIndex } from 'pages/orders/Orders';
import SyncLink from './SyncLink';

import MechBar from './MechBar';
import OneTeh from './one/OneTeh';
import mechanicsApi from '../../api/mechanics';

const LEFT_PADDING = 160;

class Teh extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object
  };

  columns = [
    { field: 'appl_id', title: 'ID' },
    { field: 'client', title: 'Клиент' },
    { field: 'serial_num', title: 'Техника' },
    { field: 'address', title: 'Адрес' },
    { field: 'supervisor', title: 'Супервайзер' },
    { field: 'action', title: '' }
  ];

  filter = [
    { title: 'Серийный номер', value: 'serial_num' },
    { title: 'ID', value: 'appl_id' },
    { title: 'Адрес', value: 'raw_address' },
    { title: 'Торговый', value: 'trade_name' }
  ];
  state = {
    items: [],
    count: 0,
    page: 0,
    perPage: 25,
    fetch: true,
    filter: {
      param: 'serial_num',
      value: '',
      passTO: 0,
      period: getIntervalIndex(this.props),
      supervisor_id: null,
      trade_id: null,
      showAll: this.props.location.search.includes('showAll') ? true : null,
      showAppointed: 0
    },
    supervisors: [],
    mechanics: [],
  };

  componentDidMount() {
    const promises = [supervisorApi.getList(), mechanicsApi.getList()];

    Promise.all(promises)
      .then(([supervisors, mechanics]) => {
        this.setState(() => ({ supervisors, mechanics }), this.requestItems);
      });
  }

  requestItems = () => {
    this.setState(() => ({ fetch: true }), () => {
      this.requestApi().then(({ items, count }) => {
        const data = items.reduce((acc, item) => {
          item.couldChecked = (!item.orders.length || item.orders[0].status !== OrderConstants.Status.APPROVED);
          item.checked = false;
          acc.push(item);

          return acc;
        }, []);

        this.setState(() => ({ items: data, count, fetch: false }));
      });
    });
  };

  requestApi(act = 'getAll') {
    const { page, perPage, filter } = this.state;
    const action = act === 'getAll' ? tehApi.getAll : tehApi.print;

    return action.call(tehApi, {
      page,
      perPage,
      [filter.param]: filter.value,
      period: filter.period,
      passTO: filter.passTO,
      supervisor_id: filter.supervisor_id,
      trade_id: filter.trade_id,
      showAll: filter.showAll,
      showAppointed: filter.showAppointed
    });
  };

  handleFilter = ({ param, value }) => {
    this.setState((state) => {
      const filter = Object.assign({}, state.filter, { param, value });
      return { page: 0, filter };
    }, this.requestItems);
  };

  handleCustomFilter = (event) => {
    const { target: { id, value } } = event;
    const param = id || 'passTO';
    this.setState((state) => {
      const filter = Object.assign({}, state.filter, { [param]: JSON.parse(value) });
      return { filter };
    });
  };

  handleChangeSupervisors = (isTrade, supervisor_id) => {
    this.setState((state) => {
      const filter = Object.assign({ page: 0 }, state.filter, {
        supervisor_id: isTrade ? null : supervisor_id,
        trade_id: isTrade ? supervisor_id : null
      });
      return { filter };
    }, this.requestItems);
  };

  handleChangePage = (page) => {
    this.setState(() => ({ page }), this.requestItems);
  };

  handleShowAll = ({ currentTarget: { name, checked } }) => (
    this.handleCustomFilter({
      target: { id: name, value: checked ? true : null }
    })
  );

  handleCreateTeh = () => {
    this.props.history.push(`/teh/appl/new`);
  };

  handleRowClick = (e) => {
    e.preventDefault();
    const appl_id = e.currentTarget.closest('tr').getAttribute('data-id');
    this.props.history.push(`/teh/appl/${appl_id}`);
  };

  handleOpenOrder = (e) => {
    e.preventDefault();
    const order_id = e.currentTarget.getAttribute('data-order-id');

    this.props.history.push(`/orders/order/${order_id}`);
  };

  handleSelectTeh = (e) => {
    const index = e.currentTarget.closest('tr').rowIndex - 1;
    const items = this.state.items;
    const item = items[index];

    item.checked = !item.checked;
    this.setState(() => ({ items: [].concat(items) }));
  };

  handleUpdateItems = (items) => {
    this.setState(() => ({ items: [].concat(items) }));
  };

  handleRemove = (e) => {
    e.stopPropagation();
    const index = e.currentTarget.closest('tr').rowIndex - 1;
    const items = this.state.items;
    const item = items[index];

    if (!item) {
      return;
    }

    const removeItem = item.status !== ApplConstants.Status.DELETED;

    if (removeItem && !confirm('Действительно удалить запись?')) { // eslint-disable-line
      return;
    }

    if (removeItem) {
      tehApi.remove(item)
        .then(() => this.requestItems())
        .catch(() => this.props.showInformer({ type: 'danger', text: ' Произошла ошибка при удалении' }));
    } else {
      tehApi.updateOne(Object.assign({}, { appl_id: item.appl_id, status: ApplConstants.Status.NEW }))
        .then(() => this.requestItems())
        .catch(() => this.props.showInformer({ type: 'danger', text: ' Произошла ошибка при восстановлении' }));
    }
  };

  handlePrint = () => {
    this.requestApi('print');
  };

  oneTeh = memoizeWith(identity, () => () => (<OneTeh updateList={this.requestItems} filter={this.state.filter} />));

  render() {
    const { match: { params: { id } } } = this.props;
    const { filter: { passTO, period, showAppointed }, supervisors } = this.state;
    return (
      <div>
        <Filter
          filter={this.filter}
          state={this.state.filter}
          onFilter={this.handleFilter}
          onPrint={this.handlePrint}
          onCreate={this.handleCreateTeh}
          width={900}
        >
          <FormGroup controlId="passTO">
            <ControlLabel style={{ width: LEFT_PADDING }}>Фильтр по ТО</ControlLabel>{' '}
            <Radio name="rg" inline value="0" checked={passTO === 0} onChange={this.handleCustomFilter}>
              Все
            </Radio>{' '}
            <Radio name="rg" inline value="1" checked={passTO === 1} onChange={this.handleCustomFilter}>
              Прошли ТО
            </Radio>{' '}
            <Radio name="rg" inline value="-1" checked={passTO === -1} onChange={this.handleCustomFilter}>
              Непрошли ТО
            </Radio>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Checkbox name="showAll" onChange={this.handleShowAll} checked={this.state.filter.showAll}>
              Показать удаленные
            </Checkbox>
          </FormGroup>
          <div>
            <FormGroup controlId="period">
              <ControlLabel style={{ width: LEFT_PADDING }}>Период</ControlLabel>{' '}
              <Radio id="period" name="period" inline value="0" checked={period === 0}
                     onChange={this.handleCustomFilter}>
                ТО-1
              </Radio>{' '}
              <Radio id="period" name="period" inline value="1" checked={period === 1 || period === 2}
                     onChange={this.handleCustomFilter}>
                ТО-2
              </Radio>
            </FormGroup>
          </div>
          <div>
            <FormGroup controlId="appointed">
              <ControlLabel style={{ width: LEFT_PADDING }}>Распределение</ControlLabel>{' '}
              <Radio id="showAppointed" name="showAppointed" inline value="0" checked={showAppointed === 0}
                     onChange={this.handleCustomFilter}>
                Все
              </Radio>{' '}
              <Radio id="showAppointed" name="showAppointed" inline value="1" checked={showAppointed === 1}
                     onChange={this.handleCustomFilter}>
                Распределенные
              </Radio>
              <Radio id="showAppointed" name="showAppointed" inline value="2" checked={showAppointed === 2}
                     onChange={this.handleCustomFilter}>
                Не распределенные
              </Radio>
            </FormGroup>
          </div>
          <br />
          <p>
            Всего записей: {this.state.count}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {this.props.user.brand.id === 2 && <SyncLink updateList={this.requestItems} />}
          </p>
        </Filter>

        <SupervisorPanel
          supervisors={supervisors}
          supervisor_id={this.state.filter.supervisor_id}
          trade_id={this.state.filter.trade_id}
          onChange={this.handleChangeSupervisors}
        />
        <br />
        <Loader isLoading={this.state.fetch}>
          <Table
            columns={this.columns}
            items={this.state.items}
            HeaderComponent={MechBar}
            RowComponent={RowComponent}
            onUpdateItems={this.handleUpdateItems}
            onOpenTeh={this.handleRowClick}
            onOpenOrder={this.handleOpenOrder}
            onSelectTeh={this.handleSelectTeh}
            onRemoveClick={this.handleRemove}
            mechanics={this.state.mechanics}
          />
          <Paginator
            count={this.state.count}
            perPage={this.state.perPage}
            page={this.state.page}
            onChange={this.handleChangePage}
          />
        </Loader>

        <Route path="/teh/appl/:id" component={this.oneTeh(id)} />
        <Route path="/teh/history/:id" component={History} />
      </div>
    );
  }
}

const getClassNameAppl = (item) => {
  switch (item.status) {
    case ApplConstants.Status.SKIPED:
      return 'info';

    case ApplConstants.Status.DELETED:
      return 'danger';

    default:
      return '';
  }
};

const RowComponent = ({ item, onOpenTeh, onOpenOrder, onSelectTeh, onRemoveClick }) => (
  <tr className={getClassNameAppl(item)} data-id={item.appl_id}>
    <td>
      <a href={`/teh/appl/${item.appl_id}`} onClick={onOpenTeh}>
        {item.appl_id}
      </a>
      <If condition={item.couldChecked}>
        <br />
        <input
          type="checkbox"
          name="meh"
          className="form-check-input"
          checked={Boolean(item.checked)}
          onChange={onSelectTeh}
        />
      </If>
    </td>
    <td>
      {item.addr.firm_title}
      <If condition={item.orders.length}>
        <div>
          {item.orders[0].mechanics.map(({ fio }) => fio).join(', ')}
          {' - '}
          <a
            href={`/orders/order/${item.orders[0].order_id}`}
            data-order-id={item.orders[0].order_id}
            onClick={onOpenOrder}
          >
            {item.orders[0].order_id}
          </a>
        </div>
      </If>
    </td>
    <td>
      <div>{item.model.title}</div>
      <h4>
        <Label bsStyle="default">{item.serial_num}</Label>
      </h4>
    </td>
    <td>
      <div>{item.addr.raw_address}</div>
      <Label bsStyle='default'>{item.addr.city}</Label>
    </td>
    <td>
      <div>{item.supervisor ? item.supervisor.fio : ''}</div>
      <Label bsStyle='default'>{item.addr.trade ? item.addr.trade.fio : ''}</Label>
    </td>
    <td>
      <div>
        <Glyphicon className="cursor-pointer" glyph="pencil" onClick={onOpenTeh} /> {' '}
        <Glyphicon
          className="cursor-pointer"
          glyph={item.status !== ApplConstants.Status.DELETED ? 'remove' : 'share-alt'}
          onClick={onRemoveClick}
        />
      </div>
    </td>
  </tr>
);

export default compose(
  withUser,
  withInformer,
  withRouter
)(Teh);
